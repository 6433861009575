.searchContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 4px 0px;
	position: relative;
}

.searchAnchor {
	width: 100%;
	position: relative;
}

.searchAnchorFocused {
	width: 60%;
	position: absolute;
}

@media (max-width: 600px) {
	.searchAnchor {
		width: 100%;
	}
}

.searchInput {
	width: 100%;
	padding: 0.5rem 2rem 0.5rem 3rem;
	font-size: 16px;
	outline: none;
	border: 2px solid var(--color-gray-200);
	transition:
		width 0.3s ease-in-out,
		transform 0.3s ease-in-out;
	height: 40px;
	color: var(--color-gray-600);
	line-height: 24px;
	background-color: var(--background-color, var(--color-white));
}

.searchInputFocused {
	border: 2px solid var(--color-blue-500);
}

.searchIcon {
	position: absolute;
	left: 16px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
	pointer-events: none;
	color: var(--color-gray-600);
}

.clearButton {
	color: var(--color-black);
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
	background: none;
	border: none;
	cursor: pointer;
	padding: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.3ms;
}

.clearButton__visible {
	opacity: 1;
	pointer-events: initial;
	transition: none;
}

.popoverContent {
	background-color: var(--background-color, var(--color-white));
	box-shadow: 0 4px 6px var(--color-black-10);
	margin-top: 0px;
	height: calc(568px - 58px);
	min-height: calc(568px - 58px);
	overflow-y: scroll;
	overflow-x: hidden;
	-ms-overflow-style: none;
	max-height: var(--radix-popover-content-available-height);
	width: var(--radix-popover-trigger-width);
	z-index: 2000;
	position: relative;
	padding-top: 3rem;
}

[data-round_corners='true'] .popoverContent {
	border-radius: 0 0 8px 8px;
}

.resultTitle {
	margin-bottom: 2rem;
}

@media (max-width: 1024px) {
	.resultTitle {
		margin-bottom: 24px;
	}
	.popoverContent {
		width: 100vw;
		left: 0 !important;
		transform: none !important;
		padding-top: 1rem;
	}
}

.resultsContainer {
	display: flex;
	flex-direction: row;
}

.columnContainer {
	width: 50%;
}

.columnContainerRight {
	margin-right: 56px;
}

.resultList {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.resultList > *:last-child {
	margin-bottom: 76px;
}

.resultList > *:last-child .resultItem {
	border-bottom: none;
}

.resultItem {
	display: flex;
	align-items: center;
	gap: 8px;
	border-bottom: 1px solid var(--color-gray-100);
	padding-bottom: 8px;
	cursor: pointer;
	color: var(--color-black);
}

.resultItemLink:focus-visible {
	outline: none;
	box-shadow: 0 0 0 2px #1049a9;
}

[data-round_corners='true'] .resultImage {
	border-radius: 8px;
}

.resultImageContainer {
	flex-shrink: 0;
}

.seeAllResults {
	z-index: 1;
	margin: 0 auto;
	color: var(--color-black);
	text-align: center;
	position: fixed;
	width: calc(100% - 5rem);
	left: 0;
	bottom: 0;
	border-top: 1px solid var(--color-gray-200);
	background-color: var(--background-color, var(--color-white));
	font-weight: 400 !important;
}

.seeAllResultsAnchor {
	color: var(--color-black);
	display: flex;
	justify-content: center;
	align-items: center;
}

.capitalize {
	text-transform: capitalize;
	font-weight: 400;
}

@media (max-width: 1024px) {
	.resultsContainer {
		display: flex;
		flex-direction: column-reverse;
	}

	.resultList {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.resultList > *:last-child {
		margin-bottom: 0px;
	}

	.columnContainer {
		position: relative;
		width: 100%;
		margin-bottom: 4rem;
	}

	.columnContainer:nth-child(2) {
		margin-bottom: 3rem;
	}

	.popoverContent {
		height: 75vh;
		padding: 1rem !important;
	}

	.searchAnchor {
		width: 100%;
	}

	.searchContainer {
		width: auto;
		display: flex;
		justify-content: center;

		position: relative;
	}

	.searchInput:not(.searchInputFocused) {
		width: 40px;
		height: 40px;
		padding: 8px 16px;
		cursor: pointer;
	}

	.searchInput::placeholder {
		color: transparent;
	}

	.searchContainer:has(.searchInput:not(.searchInputFocused)) .searchIcon {
		left: 8px;
	}

	.seeAllResults {
		margin-left: 1rem !important;
		margin-right: 1rem !important;
		width: calc(100% - 2rem);
	}
}

.gradientWhite {
	background: linear-gradient(to top, var(--background-color, var(--color-white)) 0%, rgba(255, 255, 255, 0) 100%);
}

.seeAllGradientOverlay {
	position: absolute;
	top: -41px;
	left: 0;
	width: 100%;
	height: 40px;
	z-index: 1;
}

.noResults {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100% - 3rem);
}

.searchIconSvg {
	color: var(--color-gray-600); /* Default color for light mode */
}

[data-theme='dark'] .searchIconSvg {
	color: var(--color-white);
}

[data-theme='dark'] .closeIconSvg {
	color: var(--color-white);
}

[data-theme='dark'] .clearButton {
	color: var(--color-white);
}

[data-theme='dark'] .columnContainer {
	color: var(--color-white);
}

[data-theme='dark'] .seeAllResultsAnchor {
	color: var(--color-white);
}

[data-theme='dark'] .resultItem {
	color: var(--color-white);
	border-bottom: 1px solid var(--color-white);
}

[data-theme='dark'] .searchInput {
	background-color: var(--background-color, var(--color-gray-500));
	color: white;
	border-color: var(--color-gray-400);
}

[data-theme='dark'] .searchInputFocused {
	border: 2px solid var(--color-blue-500);
}

[data-theme='dark'] .popoverContent {
	background-color: var(--background-color, var(--color-gray-600));
}

[data-theme='dark'] .gradientWhite {
	background: linear-gradient(to top, var(--background-color, var(--color-gray-600)) 0%, rgba(255, 255, 255, 0) 100%);
}

[data-theme='dark'] .seeAllResults {
	border-top: 1px solid var(--color-white);
	background-color: var(--background-color, var(--color-gray-600));
}

[data-theme='dark'] .searchInput::placeholder {
	color: var(--color-white) !important;
}

@media (max-width: 1024px) {
	[data-theme='dark'] .searchInput::placeholder {
		color: transparent !important;
	}
}

[data-theme='dark'] .searchContainer {
	color: var(--color-white);
}

[data-theme='dark'] .noResults {
	color: var(--color-white);
}

[data-theme='dark'] .resultList a:active {
	color: var(--color-white);
}

[data-round_corners='true'] .searchInput {
	border-radius: 8px;
}

@media (max-width: 932px) and (orientation: landscape) {
	.popoverContent {
		height: 100vh;
		min-height: unset;
	}
}

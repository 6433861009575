.input:active:focus,
.input:active:focus-visible {
	outline: none !important;
}

.sidebarContainer {
	width: 0;
	height: inherit;
}

.input,
.sidebarButton {
	position: absolute !important;
}

.sidebar {
	position: absolute !important;
	height: calc(100% - var(--topbar-height)) !important;
}

.sidebar a[href='#'] {
	/* Fixes EVO-1263: Category headline should not behave as link when it has no href attribute set in CMS */
	pointer-events: none;
	cursor: default;
}

@media (min-width: 1025px) {
	.__visible {
		display: flex !important; /* overriding initial rules in case of 100% topbar located in sidebar (including on resize), calculated condition name - sideBarWithTopItems */
	}
}

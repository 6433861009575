.notificationBar {
	--background-color: black;
	--text-color: white;
	--close-color: white;
	display: inline-grid;
	grid-template-columns: 1fr;
	align-items: center;
	justify-items: center;
	gap: 1rem;
	width: 100%;
	min-height: fit-content;
	background: var(--background-color);
	color: var(--text-color);
	padding: 0 1.5rem;
	/* added important in order to override default coming from uikit global css */
	font: 500 1rem / 1 var(--font-family-main) !important;
	text-align: left;
	/* counteracting pointer events set to none by wrapper on arena-view */
	pointer-events: all;
	transition: transform 0.3s ease-in-out;
}
.notificationBar.hidden {
	height: 0;
	min-height: 0;
	display: none;
}
.notificationBar:has(.closeButton) {
	grid-template-columns: 3rem 1fr 3rem;
}
.notificationBar:has(.closeButton)::before {
	content: '';
}

.content {
	display: flex;
	align-items: center;
	justify-items: center;
	padding: 0.5rem 0;
}

.icon {
	min-width: 2rem;
	min-height: 2rem;
	margin-right: 0.5rem;
}

.link {
	color: currentColor;
	text-decoration: underline;
}

.link:not(:active):focus,
.link:not(:active):focus-visible {
	outline: 2px solid var(--color-blue-500);
}

.cta {
	margin-left: 0.5rem;
}

.cta .notificationBarCta {
	height: 2rem !important;
	font: 500 0.875rem / 1 var(--font-family-main) !important;
}

.cta .notificationBarCta img{
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;
}

.cta:not(:active):focus,
.cta:not(:active):focus-visible {
	outline: 2px solid var(--color-blue-500);
}

.closeButton {
	cursor: pointer;
	background: none;
	height: 2rem;
}

.closeIcon {
	color: var(--close-color);
}

.closeButton:not(:active):focus,
.closeButton:not(:active):focus-visible {
	outline: 2px solid var(--color-blue-500);
}

[data-theme='dark'] .notificationBar {
	--background-color: white;
	--text-color: black;
	--close-color: black;
}

@media (max-width: 560px) {
	.notificationBar {
		display: flex;
	}
	.notificationBar::before {
		display: none;
	}

	.content {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;
	}
	.content:has(.icon) {
		grid-template-columns: min-content 1fr;
	}
	.text {
		margin-right: auto;
	}

	.icon {
		width: 1.5rem;
		min-width: 1.5rem;
		height: 1.5rem;
		min-height: 1.5rem;
		margin: auto 0.5rem auto auto;
	}
	.content:has(.cta) .icon {
		grid-row: span 2;
	}
	.cta {
		margin: 0 auto 0 0;
		grid-row: 2;
	}
	.notificationBar:has(.icon) .cta {
		grid-column: 2;
	}
}

.Pill {
	--default-background-color: #f9fbfd;
	--background-color: var(--default-background-color);
	--default-border-color: #e6e6e6;
	--default-color: #656263;
	--default-background-color-hover: #e6e6e6;
	--default-border-color-hover: #656263;
	--default-color-hover: #656263;
	--default-background-color-active: #bbbaba;
	--default-border-color-active: #bbbaba;
	--default-color-active: #656263;
	--default-border-color-focus: #1049a9;
}

[data-theme='dark'] .Pill {
	--default-background-color: #656263;
	--default-border-color: #656263;
	--default-color: #ffffff;
	--default-background-color-hover: #656263;
	--default-border-color-hover: #ffffff;
	--default-color-hover: #ffffff;
	--default-background-color-active: #4e4b4c;
	--default-border-color-active: #4e4b4c;
	--default-color-active: #ffffff;
}

.Pill {
	background: var(--background-color, var(--default-background-color));
	box-shadow: inset 0 0 0 2px var(--border-color, var(--default-border-color));
	filter: url('');
}

.Pill:before {
	color: var(--label-color, var(--default-color));
}

.Pill:hover {
	background: var(--background-color-hover, var(--default-background-color-hover));
	box-shadow: inset 0 0 0 2px var(--border-color-hover, var(--default-border-color-hover));
}

.Pill:hover:before {
	color: var(--label-color, var(--default-color-hover));
}

.Pill:has([id^='pill-title-']:is(:active, :checked, .active)),
.Pill:active {
	background: var(--background-color-active, var(--default-background-color-active));
	box-shadow: inset 0 0 0 2px var(--border-color-active, var(--default-border-color-active));
}

.Pill:has([id^='pill-title-']:is(:active, :checked, .active)):before,
.Pill:active:before {
	color: var(--label-color, var(--default-color-active));
}

.Pill.__no-use-borders_normal:not(:hover):not(:active),
.Pill.__no-use-borders_hover:hover,
.Pill.__no-use-borders_active:active {
	--border-color: transparent;
	border-color: transparent;
	box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
}

.Pill:focus,
.Pill:focus-visible {
	outline: none;
	box-shadow: inset 0 0 0 2px var(--default-border-color-focus, #1049a9);
}

.Pill:has([id^='pill-title-']:is(:active, :checked, .active)) {
	box-shadow: inset 0 0 0 2px var(--border-color-active, var(--default-border-color-active)) !important;
}

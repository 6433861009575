.topbar {
	--searchContainerWidth: 17.75rem;
	--hamburgerSvgColor: var(--color-gray-900);
	--searchSvgColor: var(--color-gray-600);
	--reservedLeftMargin: 3.5rem;
	position: absolute;
	display: flex !important; /* This can be removed after removing topbar from uikit*/
	align-items: center;
	width: 100%;
	height: var(--topbar-height);
	background-color: var(--background-color, var(--color-white));
	box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
	justify-content: space-between;
	transform: translate3d(0, 0, 0); /* Fixes mobile position not acting as fixed */
	transition: all 0.3s ease-in-out;
	padding: 0 var(--grid-margins) 0 1rem;
}

.homeLink {
	position: relative;
	margin-left: 4rem;
	width: 9.5rem;
	height: 100%;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	outline: none;
}

.homeLink:focus-visible:not(:active):before {
	content: '';
	position: absolute;
	width: calc(100% - 2px * 2);
	height: calc(100% - 2px * 2);
	top: 0;
	left: 0;
	border: 2px solid var(--color-blue-500);
}

.logo {
	max-height: var(--topbar-height);
	max-width: 9.5rem;
	pointer-events: none;
}

.links {
	display: none;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	align-items: center;
	justify-content: center;
	gap: 1rem;
	animation: fadeIn 0.6s ease-in-out forwards;
}

@media (max-width: 1825px) {
	.links {
		left: calc(50% - 50px);
	}
}

@media (min-width: 1024px) {
	.links {
		display: inline-flex;
	}
}

@media (max-width: 1524px) {
	.links:has(> :last-child:nth-child(6)) {
		display: none;
	}
}

@media (max-width: 1424px) {
	.links:has(> :last-child:nth-child(5)) {
		display: none;
	}
}

@media (max-width: 1224px) {
	.links:has(> :last-child:nth-child(4)) {
		display: none;
	}
}

@media (max-width: 1124px) {
	.links:has(> :last-child:nth-child(3)) {
		display: none;
	}
}

/* @media (max-width: 1184px) {
    .links:has(> :last-child:nth-child(5)) {
        display: none;
    }
} */

.link {
	position: relative;
	max-width: 8.375rem;
	width: 8.375rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font: 400 1rem / 1.5 var(--font-family-main);
	color: var(--normal-label-color, var(--color-black));
	padding: 0.5rem;
	cursor: pointer;
	overflow: visible !important;
	white-space: nowrap;
	outline: none;
	text-decoration: none;
}

.link:focus-visible:not(:active):before {
	content: '';
	position: absolute;
	width: calc(100% - 2px * 2);
	height: calc(100% - 2px * 2);
	top: 2px;
	left: 2px;
	box-shadow: inset 0 0 0 2px var(--color-blue-500);
}

.link:hover,
.link.active {
	font-weight: 600;
	background-color: var(--hover-background-color, var(--color-gray-100));
}

.searchContainer {
	display: flex;
	align-items: center;
	margin-left: auto;
	transition: width 0.3s ease-in-out;
	width: 283px;
	justify-content: flex-end;
}

.searchContainer.focused {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 45%;
	max-width: 45%;
	transition:
		width 0.3s ease-in-out,
		transform 0.3s ease-in-out;
}

.searchInput {
	transition: all 0.3s ease-in-out;
	width: 100%;
}

.searchInput.focused {
	width: 100%;
}

@media (max-width: 1024px) {
	.topbar {
		padding: 0 0.5rem 0rem 1rem;
	}
	:has(.searchContainer.focused) :global(.sidebarButton) {
		visibility: hidden !important;
	}
	.searchContainer {
		width: auto;
		transition: width 0.3s ease-in-out;
		display: block !important; /* This can be removed after removing topbar from uikit*/
	}
	.searchContainer.focused {
		position: absolute;
		left: 0;
		right: 0;
		transform: none;
		width: 100%;
		max-width: 100%;
		justify-content: center;
		padding: 0 1rem;
		transition:
			width 0.3s ease-in-out,
			transform 0.3s ease-in-out; /* Constrain the width */
	}
}

.hidden {
	display: none;
}

@media (max-width: 569px) {
	.hiddenmobile {
		display: none;
	}
	.logo.hiddenmobile {
		display: none;
	}
}

:has([data-theme='dark']) .topbar {
	--hamburgerSvgColor: var(--color-white);
	--searchSvgColor: var(--color-white);
	background-color: var(--background-color, var(--color-gray-600));
	box-shadow: none;
}

:has([data-theme='dark']) .searchContainer .searchInput {
	box-shadow: inset 0 0 0 2px var(--color-gray-400);
	background-color: var(--background-color, var(--color-gray-500));
	border-color: var(--color-gray-400);
}

:has([data-theme='dark']) .topbar {
	color: var(--color-white);
	background-color: var(--background-color, var(--color-gray-600));
}

:has([data-theme='dark']) .link {
	color: var(--normal-label-color, var(--color-white));
}

:has([data-theme='dark']) .link:hover,
:has([data-theme='dark']) .link:active {
	color: var(--normal-label-color, var(--color-white));
	background-color: var(--hover-background-color, var(--color-black));
}

:has([data-round_corners='true']) .link {
	overflow: hidden;
	border-radius: 0.5rem;
}

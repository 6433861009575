.footer {
	position: relative;
	width: 100%;
}

.hideFooter {
	display: none;
}

.container {
	display: flex;
	height: 15rem;
	width: 100%;
	padding: 3.75rem 6.25rem;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
}

/* footer logo */
.image {
	overflow: hidden;
	margin-bottom: 1rem;
}

.image img {
	width: auto;
	height: auto;
	max-height: 3rem;
	max-width: 9.5rem;
}

/* blocks for the links */
.blocks {
	align-self: center;
	display: flex;
	gap: 6.25rem;
}

.blocks ul li {
	margin-bottom: 1rem;
}

.blocks ul li:last-child {
	margin-bottom: 0;
}

.links {
	display: flex;
	gap: 1rem;
}

.blocks ul li a,
.blocks ul li button {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.blocks ul li a:has(img),
.blocks ul li button:has(img) {
	gap: 0.5rem;
}

.block {
	align-self: center;
	max-width: 20rem;
}

.social {
	position: relative;
	margin-top: 1rem;
}

.container ul li a span,
.container ul li button span,
.container p {
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1rem;
}

.buttonGroupLink {
	background: none;
}

@media (max-width: 1200px) {
	.blocks {
		gap: 3.25rem;
	}

	.container {
		padding: 3.75rem 4.5rem;
	}
}

@media (max-width: 1024px) {
	.blocks {
		width: 100%;
		flex-direction: column;
		gap: 2.5rem;
	}

	.block {
		align-self: flex-start;
		width: 100%;
		margin-bottom: 2.5rem;
	}

	.container {
		padding: 2.5rem 8.19rem 2.5rem 3rem;
		flex-direction: column;
		height: auto;
	}
}

@media (max-width: 480px) {
	.container {
		padding: 2.5rem 3rem;
	}
}

@media (min-width: 1024px) {
	.sidebarClosedWithIcons {
		float: right;
		width: calc(100% - 3.5rem); /* 3.5rem is the size of the sidebar collapsed with icons */
	}
}
